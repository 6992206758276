import { fetchUtils } from 'react-admin';
import { stringify } from 'query-string';
import httpClient from './auth/httpClient';

const authUrl = process.env.REACT_APP_API_ENDPOINT_AUTH+'/api/auth';
const registerUrl = process.env.REACT_APP_API_ENDPOINT+'/api';
const apiUrl = {
  user: authUrl,
  session: authUrl,
  noticia: registerUrl,
};

export default {
  getList: (resource, params) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const query = {
      order: field,
      page,
      per_page: perPage, 
      sort: order,
      ...params.filter,
    };
    if (params.filter.q)
      query.q = params.filter.q;
    if (resource === 'user') 
      query.attributes = ['id', 'username', 'email', 'phone', 'scope'];
    
    const url = `${apiUrl[resource]}/${resource}?${stringify(query)}`;
    return httpClient(url).then(({ headers, json }) => ({
      data: json.items,
      total: json.total_count,
    }));
  },

  getOne: (resource, params) => {
    return httpClient(`${apiUrl[resource]}/${resource}/${params.id}`).then(({ json }) => ({
      data: json,
    }));
  },

  getMany: (resource, params) => {
    return Promise.all(
      params.ids.map(id => httpClient(`${apiUrl[resource]}/${resource}/${id}`))
    ).then(responses => { 
      const list = responses.map(({ json }) => json );
      return { data: list };
    });
  },

  getManyReference: (resource, params) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const query = {
      ...fetchUtils.flattenObject(params.filter),
      [params.target]: params.id,
      sort: order,
      page,
      per_page: perPage,
      order: field
    };
    const url = `${apiUrl[resource]}/${resource}?${stringify(query)}`;

    return httpClient(url).then(({ headers, json }) => ({
        data: json.items,
        total: json.total_count,
    }));
  },

  update: (resource, params) =>
    httpClient(`${apiUrl[resource]}/${resource}/${params.id}`, {
      method: 'PUT',
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({ data: { id: params.id } })),

  updateMany: (resource, params) =>
    Promise.all(
      params.ids.map(id =>
        httpClient(`${apiUrl[resource]}/${resource}/${id}`, {
          method: 'PUT',
          body: JSON.stringify(params.data),
        })
      )
    ).then(responses => ({ data: responses.map(({ json }) => json.id) })),

  create: (resource, params) =>
    httpClient(`${apiUrl[resource]}/${resource}`, {
      method: 'POST',
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({
      data: { ...params.data, id: json.id },
    })),

  delete: (resource, params) =>
    httpClient(`${apiUrl[resource]}/${resource}/${params.id}`, {
      method: 'DELETE',
    }).then(({ json }) => ({ data: json })),

  deleteMany: (resource, params) => 
    Promise.all(
      params.ids.map(id =>
        httpClient(`${apiUrl[resource]}/${resource}/${id}`, {
          method: 'DELETE',
        })
      )
    ).then(responses => ({ data: responses.map(({ json }) => json.id) })),
};
