import * as React from "react";
import { Admin, Resource } from 'react-admin';
import dataProvider from './dataProvider';
import authProvider from './auth/authProvider';
import i18nProvider from './i18n/i18nProvider';
import users from './users';
import sessions from './sessions';
import noticias from './noticias';
import { CustomLayout } from './Layout';

const App = () => (
  <Admin
    disableTelemetry
    dataProvider={dataProvider}
    authProvider={authProvider}
    i18nProvider={i18nProvider}
    layout={CustomLayout}
  >
    {scope => [
      (scope === 'admin') ? [
        <Resource {...noticias} />,
      ] : (scope === 'superadmin') ? [
        <Resource {...users} />,
        <Resource {...sessions} />
      ] : null
    ]}
  </Admin>
);
export default App;
